import './App.css';

import splash1 from './assets/images/splash-001.jpg';
import splash2 from './assets/images/splash-002.jpg';
import splash3 from './assets/images/splash-003.jpg';

const splashes = [splash1, splash2, splash3];

const randomSplash = splashes[Math.floor(Math.random() * splashes.length)];

const refreshPage = ()=>{
   window.location.reload();
}

function App() {
  return (
    <div>
      <img src={randomSplash} alt="logo" id="logo" draggable="false" onClick={refreshPage} />
    </div>
  );
}

export default App;
